<template>
    <div>
        <Header :link="'hide'"/>

        <div class="px-3">
            <ul class="pt-3 pb-4 mb-2 d-flex text-center fw-500">
                <li class="rounded-start py-3 w-50 bg-yellow-1 fw-bold" @click="$router.push('/signin')">LOG IN</li>
                <li class="rounded-end py-3 w-50 bg-yellow-4 text-gray-500"  @click="$router.push('/signup')">SIGN UP</li>
            </ul>

            <div class="mb-4">
                <div class="rounded-1 h-px-48 bg-white flex-between-center mb-3">
                    <div class="px-2">
                        <img src="@/assets/img/layout_icon/email.svg" alt="email" width="30">
                    </div>
                    <input type="text" class="form-control border-0 h-100 fs-px-12" placeholder="Enter your email" v-model="email"  @keyup.enter="Login()">
                </div>
                <div class="rounded-1 h-px-48 bg-white flex-between-center">
                    <div class="px-2">
                        <img src="@/assets/img/layout_icon/lock.svg" alt="password" width="30">
                    </div>
                    <input :type="pw_show? 'text' : 'password'" class="form-control border-0 h-100 fs-px-12" placeholder="Enter your password" v-model="password" @keyup.enter="Login()">
                    <div class="px-2">
                        <img src="@/assets/img/layout_icon/show.svg" alt="show password" width="30" v-show="!pw_show" @click="pw_show = true">
                        <img src="@/assets/img/layout_icon/hide.svg" alt="hide password" width="30" v-show="pw_show" @click="pw_show = false">
                    </div>
                </div>
            </div>

            <div class="text-center text-white fs-px-12 mb-4">
                <router-link to="/reset-pw" class="text-decoration-underline">
                    Forgot password?
                </router-link>
                
            </div>
            <div class="text-center text-white fs-px-12 mb-4">
                <router-link to="/confirm-email" class="text-decoration-underline">
                    Send Verification Email again
                </router-link>
            </div>

            <div class="mb-5">
                <button class="py-3 border-0 rounded-1 bg-yellow-1 w-100 flex-center-center btn fw-bold" @click="Login()">
                    LOG IN
                </button>
            </div>

            <div class="text-white lh-1 ">
                <span class="fs-px-12">By entering the site, I confirm that I am 18 years old or more and I have read the <span class="text-decoration-underline pointer" @click="TermsPopupOn(0)">Terms of Service</span></span>
            </div>
        </div>


        <!-- Reset Password -->
        <b-modal v-model="resetPwModal" centered hideHeader body-class="pb-0" footer-class="pt-0">
            <div>
                <h6 class="text-center mb-3 py-3">Reset Password</h6>
                
                <div>
                    <div class="rounded-1 h-px-48 bg-white flex-between-center border border-gray-300 mb-3">
                        <div class="px-2">
                            <img src="@/assets/img/layout_icon/lock.svg" alt="password" width="30">
                        </div>
                        <input :type="reset_pw_show1? 'text' : 'password'" class="form-control border-0 h-100 fs-px-12" placeholder="Enter new password" v-model="new_password">
                        <div class="px-2">
                            <img src="@/assets/img/layout_icon/show.svg" alt="show password" width="30" v-show="!reset_pw_show1" @click="reset_pw_show1 = true">
                            <img src="@/assets/img/layout_icon/hide.svg" alt="hide password" width="30" v-show="reset_pw_show1" @click="reset_pw_show1 = false">
                        </div>
                    </div>
                    <div class="rounded-1 h-px-48 bg-white flex-between-center border border-gray-300">
                        <div class="px-2">
                            <img src="@/assets/img/layout_icon/lock.svg" alt="password" width="30">
                        </div>
                        <input :type="reset_pw_show2? 'text' : 'password'" class="form-control border-0 h-100 fs-px-12" placeholder="Confirm password" v-model="new_password2">
                        <div class="px-2">
                            <img src="@/assets/img/layout_icon/show.svg" alt="show password" width="30" v-show="!reset_pw_show2" @click="reset_pw_show2 = true">
                            <img src="@/assets/img/layout_icon/hide.svg" alt="hide password" width="30" v-show="reset_pw_show2" @click="reset_pw_show2 = false">
                        </div>
                    </div>
                </div>

                <div class="h-px-38 lh-1 flex-start-center">
                    <span class="text-danger fs-px-12" v-show="pw_match == false">
                        Your confirmation password does not match the new password
                    </span>
                </div>
            </div>
            
            <template #modal-footer>
                <div class="d-flex w-100">
                <button class="py-3 fs-px-12 btn btn-transparent w-30 " @click="resetPwModal=false">CANCEL</button>
                <button class="py-2 fs-px-12 fw-bold btn btn-transparent bg-yellow-1 w-70" @click="ResetPw()">Reset password</button>
                </div>
            </template>
        </b-modal>

        <TermsPopup ref="TermsPopup" />
        <Loading v-if="loading"/>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'
import { BModal } from 'bootstrap-vue'
import TermsPopup from '@/components/terms/Terms.vue'
import Loading from '@/components/common/Loading.vue'
const CryptoJS = require("crypto-js");

export default {
    components:{
        Header, BModal, TermsPopup,Loading
    },
    data(){
        return{
            email: '',
            password: '',
            loading : false,
            new_password: '',
            new_password2: '',


            // front ui
            resetPwModal: false,

            pw_show: false,

            reset_pw_show1: false,
            reset_pw_show2: false,
            pw_match: null
        }
    },
    methods:{
        Login(){

            const email = this.email;
            const password = this.password;
            const body = {email,password};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();

            
            
            this.$http.post('/member/login/login',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)
                            
                            const token = body.token;
                            const profile = body.profile;
                            const info = {token,profile};

                            this.$store.dispatch('SETLOGIN',{info}).then(
                                ()=>{
                                    // this.$bvModal.show("login-modal");        
                                    location.href = `/`;
                                }
                            )
                            // alert(body.info.token)
                            // this.$pp({
                            //     msg: 'A verification link has been sent to the email you entered.', 
                            //     is_confirm: false, 
                            //     auto_hide:false
                            // })
                            // this.$router.push('/signin')
                            // return false;
                            
                        }else if(res.data.code =="100"){
                            this.$pp({
                                msg: 'Unregisted email address or wrong password', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                            return false;
                        }
                    }
                }   
            )
        },
        ResetPw(){
            this.resetPwModal = false;

            // 비밀번호 매치하지 않을시
            // this.pw_match = false;
        },
        TermsPopupOn(idx){
            this.$refs.TermsPopup.idx = idx;
            this.$refs.TermsPopup.pp = true;
        }
    },
    mounted(){
        if( this.$route.params.resetPw ){
            this.resetPwModal = true;
        }

        let has_toast = sessionStorage.getItem("toast_msg");
        if( has_toast ){
            this.$pp({
                msg: has_toast, 
                is_confirm: false, 
                auto_hide:true
            })
            sessionStorage.removeItem("toast_msg");
        }
    }

}
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
</style>