<template>
    <div>
        <Header />
        
        <div class="px-3 pt-3">
            <div class="border-bottom border-yellow-1 mb-3">
                <h5 class="text-white mb-2 fw-bold fs-px-18">Set my profile</h5>

                <div class="flex-between-center">

                    <div>
                        <p class="text-white">Profile Picture (Optional)</p>
                        <label class="btn btn-sm text-white border mt-3 d-inline-flex justify-content-between align-items-center">
                            <input type="file" hidden @change="UploadFile" accept="image/*">
                            <img src="@/assets/img/layout_icon/camera.svg" width="20"> <span class="ms-1 fs-px-12">Upload</span>
                        </label>
                    </div>
                    <div class="flex-center-center flex-column mb-4">
                        <img src="@/assets/img/user-icon.svg" alt="profile" width="90" v-if="profile_img == ''">
                        <img :src="profile_img" alt="profile" width="90" height="90" class="rounded-circle object-fit-cover" v-else>
                    </div>
                </div>
            </div>

            <div class="border-bottom border-yellow-1">
                <p class="text-white mb-2">Nickname</p>
                <div class="flex-between-center">
                    <div class="rounded-1 h-px-48 bg-white flex-between-center flex-grow-1">
                        <input type="text" class="form-control border-0 h-100" placeholder="Nickname" v-model="nickname" maxlength="15" @change="ChangeNick()">
                        <div class="pe-2 w-px-48">
                            <img src="@/assets/img/layout_icon/x_circle.svg" alt="remove form" width="28" v-if="nickname !=''" @click="nickname=''">
                        </div>
                    </div>
                    <button class="h-px-48 border-0 rounded-1 bg-yellow-1 flex-center-center btn w-px-120 ms-2" @click="CheckNick()">
                        <small class="fs-px-12 lh-1">Check<br>Availability</small>
                    </button>
                </div>

                <ul class="text-burgundy-4 py-3">
                    <li class="fs-px-12 d-flex mb-2">
                        <span class="me-2">·</span>
                        <span>Minimum 2 characters, maximum 15 characters</span>
                    </li>
                    <li class="fs-px-12 d-flex mb-2">
                        <span class="me-2">·</span>
                        <span>No requirement for uppercase, lowercase letters</span>
                    </li>
                    <li class="fs-px-12 d-flex mb-2">
                        <span class="me-2">·</span>
                        <span>Can not use special characters !@#$%^&*()-</span>
                    </li>
                </ul>
            </div>

            <!-- <div class="border-bottom border-yellow-1 text-end py-3 mb-3"> -->
            <div class="text-end py-3 mb-3">
                <div class="flex-between-center text-white">
                    <span>UID</span>
                    <span>{{uid}}</span>
                </div>
                <span class="text-burgundy-4 fs-px-12">* UID can not be modified</span>
            </div>

            <!-- <div class="mb-5">
                <p class="text-white mb-2">Date of Birth</p>

                <div class="flex-between-center">
                    <div class="rounded-1 h-px-48 bg-white flex-between-center w-100 me-3">
                        <select class="form-select border-0 h-100 fs-px-12" v-model="birth_day">
                            <option value="">Day</option>
                            <option v-for="(item,index) in day_list" :key="index" :value="item">{{item}}</option>
                        </select>
                    </div>
                    <div class="rounded-1 h-px-48 bg-white flex-between-center w-100 me-3">
                        <select class="form-select border-0 h-100 fs-px-12" v-model="birth_month">
                            <option value="">Month</option>
                            <option v-for="(item,index) in month_list" :key="index" :value="item">{{item}}</option>
                        </select>
                    </div>
                    <div class="rounded-1 h-px-48 bg-white flex-between-center w-100">
                        <select class="form-select border-0 h-100 fs-px-12" v-model="birth_year">
                            <option value="">Year</option>
                            <option v-for="(item,index) in year_list" :key="index" :value="item">{{item}}</option>
                        </select>
                    </div>
                </div>
            </div> -->

            <div>
                <button class="py-3 border-0 rounded-1 bg-yellow-1 w-100 flex-center-center btn fw-bold" @click="Registration()">
                    Complete Registration
                </button>
            </div>
        </div>
        <Loading v-if="loading"/>
    </div>
</template>

<script>
import Header from '@/components/common/Header.vue'

import AWS from 'aws-sdk';
const CryptoJS = require("crypto-js");
import Loading from '@/components/common/Loading.vue'

export default {
    components:{
        Header,Loading
    },
    data(){
        return{
            nickname_valid: false,
            nickname: '',
            uid: '',
            profile_img: '',
            Bucket : process.env.VUE_APP_AWSBUCKETNAME,
            region : process.env.VUE_APP_AWSBUCKETREGION,
			IdentityPoolId: process.env.VUE_APP_AWSPOOLID,
			AWSURL: process.env.VUE_APP_AWSURL,

            // front ui
            today: new Date(),
            year_list: [],
            month_list: [],
            day_list: [],
            loading:false,
            code :this.$route.params.code
        }
    },
    mounted(){
        this.GetInfo();
    },
    methods:{
        GetInfo(){
            const code =  this.code;
            
            const body = {code};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/member/mailing/GetInfo',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            const e_body = res.data.body;
                            const bytes  = CryptoJS.AES.decrypt(e_body,process.env.VUE_APP_SECRET_TOKEN);
                            const d_res = bytes.toString(CryptoJS.enc.Utf8);
                            const body = JSON.parse(d_res)

                            this.uid = body.info.uid;
                        }else if(res.data.code =="100"){
                            this.$pp({
                                msg: 'This link has already been used.', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                            // this.$router.push(`/signin`)
                            return false;
                        }
                    }
                }   
            )

        },
        ProfileChange(){
            const code =  this.code;
            const nickname = this.nickname;
            const profile_img = this.profile_img;
            
            const body = {code,nickname,profile_img};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/member/mailing/EditMemberProfile',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$alert("Registration Success!").then(()=>{
                                this.$router.push('/signin')
                            })
                        }
                    }
                }   
            )

        },
        ChangeNick(){
            this.nickname_valid = false;
        },
        CheckNick(){
            const regex = /^[\p{L}\d]{2,15}$/u;

            // 0603 공백일 때 조건 추가
            
            if (!this.nickname || this.nickname.trim() === "") {
                this.nickname_valid = false;
                this.$pp({
                    msg: 'Nickname can not be blank!', 
                    is_confirm: false, 
                    auto_hide: true
                });

                return false;
            }

            if (this.nickname.match(regex)) {
                this.nickname_valid = true;
            } else {
                this.nickname_valid = false;
                this.$pp({
                    msg: 'nickname includes unsupported character', 
                    is_confirm: false, 
                    auto_hide:true
                })
                return false;
            }
            

            const nickname = this.nickname;
            const body = {nickname};
            const req = CryptoJS.AES.encrypt(JSON.stringify(body),process.env.VUE_APP_SECRET_TOKEN).toString();
            this.$http.post('/member/mailing/CheckNickName',{req}).then(
                (res) =>  {
                    if(res.status == 200){
                        this.loading = false;
                        if(res.data.code =="200"){
                            this.$pp({
                                msg: 'Available', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                        }else if(res.data.code =="100"){
                            this.nickname_valid = false;
                            this.$pp({
                                msg: 'Unavailable', 
                                is_confirm: false, 
                                auto_hide:true
                            })
                        }
                    }
                }   
            )

        },
        
        Registration(){
            let result = true;

            if( !this.nickname_valid ){
                this.$pp({
                    msg: 'Check Nickname availability first', 
                    is_confirm: false, 
                    auto_hide:true
                })
                result = false;
                return;
            }
            
            if( result == true ){
                this.ProfileChange();
                
            }
        },
        UploadFile(e){
            const files = e.target.files;

            const file = files[0];
            const date = new Date();
            const timestamp = date.getTime();
            const fileName = timestamp+file.name;

            if (file && file.type.includes('image')) {
                this.profile_img = file;
                AWS.config.update({region: this.region,credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: this.IdentityPoolId})});
                var s3 = new AWS.S3({apiVersion: "2006-03-01",params: { Bucket: this.Bucket}});
                s3.upload({Key: fileName, Body : file, ACL:'public-read','ContentType':`${file.type}`}, (err)=>{
                    if(err == null){
                    const path = this.AWSURL + fileName;
                    
                    this.profile_img = path;
                    }
                })
            } else {
                this.selected_file = null;
                this.profile_img = null;
            }
			
			
			

            
		},
        ReadImage(event) {
            const file = event.target.files[0];
            
            if (file && file.type.includes('image')) {
                this.selected_file = file;
                this.profile_img = URL.createObjectURL(file);
            } else {
                this.selected_file = null;
                this.profile_img = null;
            }
        },
        ChkNick(){
            const regex = /^[\p{L}\d]{2,10}$/u;

            if (this.nickname.match(regex)) {
                this.nickname_valid = true;
            } else {
                this.nickname_valid = false;
                this.$pp({
                    msg: 'Unavailable', 
                    is_confirm: false, 
                    auto_hide:true
                })
            }
            
            if( this.nickname_valid ){
                this.$pp({
                    msg: 'Available', 
                    is_confirm: false, 
                    auto_hide:true
                })
            }else {
                this.nickname_valid = false;
                this.$pp({
                    msg: 'Unavailable', 
                    is_confirm: false, 
                    auto_hide:true
                })
            }
        },
        ChkBirth(){
            if( this.birth_day != '' || this.birth_month != '' || this.birth_year != '' ){
                let valid_result = true;

                var y = this.birth_year;
                var m = this.birth_month - 1;
                var d = this.birth_day;

                var date = new Date(y, m, d);

                if (date.getFullYear() == y && date.getMonth() == m && date.getDate() == d) {
                    valid_result = true;
                }
                else {
                    valid_result = false;
                }

                if( valid_result == false ){
                    this.$pp({
                        msg: 'Invalid date of birth', 
                        is_confirm: false, 
                        auto_hide:true
                    })
                    return false;

                }else{
                    const currentDate = new Date();
                    const minAgeDate = new Date(
                        currentDate.getFullYear() - 18,
                        currentDate.getMonth(),
                        currentDate.getDate()
                    );
                    
                    if( date > minAgeDate ){
                        this.$pp({
                            msg: 'You must be at least 18 years old to use OCB service.', 
                            is_confirm: false, 
                            auto_hide:true
                        })
                        return false;
                    }else{
                        return true;
                    }
                }

            }else{
                return true;
            }
        }
    },
    // mounted(){
    //     let today_year = this.today.getFullYear();
    //     let year_list = [];
    //     let month_list = [];
    //     let day_list = [];

    //     for(let i=1900; i<=today_year; i++){
    //         year_list.push(i);
    //     }
    //     for(let i=1; i<=12; i++){
    //         month_list.push(i);
    //     }
    //     for(let i=1; i<=31; i++){
    //         day_list.push(i);
    //     }

    //     this.year_list = year_list;
    //     this.month_list = month_list;
    //     this.day_list = day_list;
    // }

}
</script>

<style>

</style>